







































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";

import { mapActions, mapState } from "vuex";
import rules from "@/components/account/rules";
import { notifyError, notifyInfo } from "@/components/Notification";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "btn-create-accessory",
  props: {
    btnProps: {
      type: Object,
      default: () => ({}),
    },
    expanded: Boolean,
  },
  data() {
    return {
      dialog: false,
      formValid: false,
      name: "Accessory",
      rules,
      minprice: 0,
      maxprice: 0,
      price: 0,
      loading: false,
      form: {
        name: "",
        type: "Accessory",
      },
    };
  },
  mounted() {
    this.name = this.dataCRM.nomenclators
      ? this.dataCRM.nomenclators.accessories
      : "Accessories";
    if (this.name == "Accessories") {
      this.name = "Accessory";
    } else {
      this.name = this.name.substring(0, this.name.length - 1);
    }
  },
  computed: {
    ...mapState(["dataCRM"]),
    PriceValid() {
      if (this.minprice == 0 && this.maxprice == 0 && this.price == 0) {
        return true;
      }
      if (this.minprice != 0 || this.maxprice != 0) {
        if (
          Number(this.minprice) > Number(this.maxprice) &&
          Number(this.price) <= Number(this.minprice) &&
          Number(this.price) >= Number(this.maxprice)
        ) {
          return true;
        } else if (
          Number(this.minprice) < Number(this.maxprice) &&
          Number(this.price) >= Number(this.minprice) &&
          Number(this.price) <= Number(this.maxprice)
        ) {
          return true;
        }
        if (this.minprice == this.maxprice && this.price == this.minprice) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions("crmSettingsModule", ["actCreateSubtypeNomenclator"]),
    create(): void {
      if (this.minprice > this.maxprice) {
        const temp = this.minprice;
        this.minprice = this.maxprice;
        this.maxprice = temp;
      }
      let body = {};
      if (this.minprice != 0 && this.maxprice != 0) {
        body = {
          name: this.form.name,
          type: this.form.type,
          price: Number(this.price),
          minValue: Number(this.minprice),
          maxValue: Number(this.maxprice),
        };
      } else {
        if (this.maxprice != 0) {
          body = {
            name: this.form.name,
            type: this.form.type,
            maxValue: Number(this.maxprice),
            price: Number(this.price),
          };
        } else {
          body = { name: this.form.name, type: this.form.type };
        }
      }
      this.loading = true;
      this.actCreateSubtypeNomenclator(body)
        .then(() => {
          this.loading = false;
          this.form.name = "";
          (this as any).$refs.form.resetValidation();
          notifyInfo(`The ${this.name} has been created successfully`);
          this.resetform();
          (this.dialog = false), this.$emit("created");
        })
        .catch((error: any) => {
          this.loading = false;
          this.dialog = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    resetform() {
      this.form.name = "";
      this.minprice = 0;
      this.maxprice = 0;
      this.price = 0;
    },
    close() {
      this.dialog = false;
      this.resetform();
    },
  },
});
